// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artwork-js": () => import("./../../../src/pages/artwork.js" /* webpackChunkName: "component---src-pages-artwork-js" */),
  "component---src-pages-blackhairsmatter-alimatou-js": () => import("./../../../src/pages/blackhairsmatter/alimatou.js" /* webpackChunkName: "component---src-pages-blackhairsmatter-alimatou-js" */),
  "component---src-pages-blackhairsmatter-aminata-js": () => import("./../../../src/pages/blackhairsmatter/aminata.js" /* webpackChunkName: "component---src-pages-blackhairsmatter-aminata-js" */),
  "component---src-pages-blackhairsmatter-assia-js": () => import("./../../../src/pages/blackhairsmatter/assia.js" /* webpackChunkName: "component---src-pages-blackhairsmatter-assia-js" */),
  "component---src-pages-blackhairsmatter-fatou-js": () => import("./../../../src/pages/blackhairsmatter/fatou.js" /* webpackChunkName: "component---src-pages-blackhairsmatter-fatou-js" */),
  "component---src-pages-blackhairsmatter-koumba-js": () => import("./../../../src/pages/blackhairsmatter/koumba.js" /* webpackChunkName: "component---src-pages-blackhairsmatter-koumba-js" */),
  "component---src-pages-blackhairsmatter-lika-js": () => import("./../../../src/pages/blackhairsmatter/lika.js" /* webpackChunkName: "component---src-pages-blackhairsmatter-lika-js" */),
  "component---src-pages-components-hero-js": () => import("./../../../src/pages/components/hero.js" /* webpackChunkName: "component---src-pages-components-hero-js" */),
  "component---src-pages-components-layout-js": () => import("./../../../src/pages/components/layout.js" /* webpackChunkName: "component---src-pages-components-layout-js" */),
  "component---src-pages-components-top-nav-js": () => import("./../../../src/pages/components/top-nav.js" /* webpackChunkName: "component---src-pages-components-top-nav-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-putamask-curiosity-js": () => import("./../../../src/pages/putamask/curiosity.js" /* webpackChunkName: "component---src-pages-putamask-curiosity-js" */),
  "component---src-pages-putamask-devotion-js": () => import("./../../../src/pages/putamask/devotion.js" /* webpackChunkName: "component---src-pages-putamask-devotion-js" */),
  "component---src-pages-putamask-toughness-js": () => import("./../../../src/pages/putamask/toughness.js" /* webpackChunkName: "component---src-pages-putamask-toughness-js" */)
}

